import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError, change } from "redux-form";
import { toastr } from "react-redux-toastr";

import { LOGIN } from "../../actions/Constants";

import api from "../../api/apiUtil";
import TextIconField from "../../components/Fields/TextIconField";
import fieldNormalize from "../../components/Normalize/FieldNormalize";

import logo from "../../img/e-registro-logo.png";
import signInMicrosoft from "../../img/loginMS.png";
import backgroundLogin from "../../img/e-registro-new-login.jpg";
import * as Msal from "msal";
import ReCAPTCHA from "react-google-recaptcha";
import FieldValidation from "../../components/Validation/FieldValidation";
import queryString from "query-string";
import base64url from "base64url";
import SvgIcon from "react-icons-kit";
import { ic_fingerprint } from "react-icons-kit/md/ic_fingerprint";
import Cookies from "js-cookie";
import HeaderAlias from "../../components/Layout/HeaderAlias";
import Footer from "../../components/Layout/Footer";
import Modal from "react-bootstrap4-modal";
import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import SelectField from "../../components/Fields/SelectField";

function b64stringToUint8Array(b64_string) {
  return Uint8Array.from(atob(b64_string), (c) => c.charCodeAt(0));
}

// takes a uint8 array and returns a b64-encoded string representation of it.
// Maybe a function for this wasn't needed since it does one thing (tm) but it
// looks quirky and I feel better having it tested as a unit.
function Uint8ArrayToB64String(array) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(array)));
}

function publicKeyCredentialToJSON(pubKeyCred) {
  const enc = new TextEncoder();
  if (pubKeyCred instanceof ArrayBuffer) {
    return Uint8ArrayToB64String(pubKeyCred);
  } else if (pubKeyCred instanceof Array) {
    return pubKeyCred.map(publicKeyCredentialToJSON);
  } else if (pubKeyCred instanceof Object) {
    const obj = {};
    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }
    return obj;
  } else return pubKeyCred;
}
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
    const values = queryString.parse(this.props.location.search);
    this.state = {
      captchaNotSelect: true,
      samlError: values.samlError,
      config: null,
      recuperouConfig: false,
      valueCaptcha: null,
      allowBiometric: false,
      recovery: values.recovery,
      showSelectFin: false,
      financeiras: [],
      biometria: false,
      assertionObj: null,
      show2fa: false,
    };
    if (process.env.REACT_APP_LOGIN_SAML) {
      fetch(process.env.REACT_APP_LOGIN_SAML)
        .then((url) => url.text())
        .then((url) => {
          this.setState({ samlLoginUrl: url });
        });
    }
    if (values.samlToken) {
      this.props.onLogin({ id_token: values.samlToken });
    }
  }

  componentDidMount() {
    Promise.resolve(api.Auth.ping()).then((r) => {
      Promise.resolve(api.Configuracao.getPorAtributo("template")).then((res) => {
        if (res) {
          this.setState({
            config: JSON.parse(res.valor),
          });
        }
        this.setState({ recuperouConfig: true });
      });
      if (this.state.recovery) {
        this.loginRecoveryKey(this.state.recovery);
      } else if (navigator.credentials && window.PublicKeyCredential) {
        window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((a) => {
          console.log(a);
          var deviceUUID = Cookies.get("deviceUUID");
          this.setState({ allowBiometric: a && deviceUUID });
        });
      }
    });
    //api.Auth.ping(); // para buscar token CSRF
  }

  onChange(value) {
    this.setState({ captchaNotSelect: !this.state.captchaNotSelect, valueCaptcha: value });
  }

  render() {
    const { handleSubmit } = this.props;
    if (!this.state.recuperouConfig) {
      return null;
    }
    return (
      <>
        <HeaderAlias></HeaderAlias>
        <div
          className="login-container login-background"
          style={{ backgroundImage: `url(${this.state.config && this.state.config.img_fundo_login ? "data:image/png;base64," + this.state.config.img_fundo_login : backgroundLogin})` }}
        >
          {/* <div className="splash"> <div className="color-line"></div><div className="splash-title"><h1>E-REGISTRO</h1><p>Registro de contrato de financiamento de veículos </p><div className="spinner"> <div className="rect1"></div> <div className="rect2"></div> <div className="rect3"></div> <div className="rect4"></div> <div className="rect5"></div> </div> </div> </div> */}
          <div className="color-line-ereg"></div>
          <div className="login-form">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="text-center m-b-md">
                      {
                        process.env.REACT_APP_LOGO_URL ?
                        <img alt="" src={process.env.REACT_APP_LOGO_URL} className="mr-3 align-self-center mx-auto" style={{maxHeight: '30px'}}/>
                        :
                        <h3>E-REGISTRO</h3>
                      }

                        <small></small>
                    </div> */}
                <div className="hpanel">
                  <div className="panel-body-round">
                    <div className="login-img-container">
                      <img src={this.state.config && this.state.config.logo_sistema ? `data:image/jpeg;base64,${this.state.config.logo_sistema}` : logo} />
                    </div>
                    <form id="loginForm" onSubmit={handleSubmit(this.formSubmit)}>
                      <Field
                        required
                        validate={!this.state.showSelectFin ? [FieldValidation.required] : false}
                        name="username"
                        label="Usuário"
                        component={TextIconField}
                        normalize={fieldNormalize.CPF}
                        icon="fa-user"
                        type="text"
                        placeholder="000.000.000-00"
                        title="Digite seu CPF cadastrado no sistema"
                      />
                      <Field
                        required
                        validate={!this.state.showSelectFin ? [FieldValidation.required] : false}
                        name="password"
                        label="Senha"
                        component={TextIconField}
                        icon="fa-key"
                        type="password"
                        placeholder="******"
                        title="Digite sua senha de acesso"
                        describePassword={true}
                      />

                      <ReCAPTCHA
                        className="btn-reCaptcha"
                        sitekey="6LfzBOAUAAAAAP_xf_UoEARv84VecG1-14RZ3BtB"
                        onChange={this.onChange.bind(this)}
                        onExpired={() => this.setState({ captchaNotSelect: true })}
                      />

                      <button ref="btn" type="submit" disabled={this.state.captchaNotSelect} className="btn btn-alternative btn-login btn-block" to="/secure">
                        ENTRAR
                      </button>

                      <Link to="/forgot" className="btn btn-link-blue btn-block">
                        Esqueceu a senha?
                      </Link>
                    </form>
                    {process.env.REACT_APP_LOGIN_MS || process.env.REACT_APP_LOGIN_SAML ? <div className="border-top" /> : ""}
                    {process.env.REACT_APP_LOGIN_MS ? (
                      <form id="loginMSForm">
                        <button type="submit" className="btn btn-ms" to="/secure" onClick={handleSubmit(this.loginMicrosoft.bind(this))}>
                          <img src={signInMicrosoft} />
                        </button>
                      </form>
                    ) : (
                      ""
                    )}
                    {process.env.REACT_APP_LOGIN_SAML ? (
                      <form id="loginSAMLForm">
                        <a className="btn btn-ms" href={this.state.samlLoginUrl}>
                          <img src={signInMicrosoft} />
                        </a>
                      </form>
                    ) : (
                      ""
                    )}
                    {this.state.allowBiometric ? (
                      <div className="border-top">
                        <br />
                        <button className="btn btn-alternative btn-login btn-block" onClick={this.loginBiometric.bind(this)}>
                          <SvgIcon size={20} icon={ic_fingerprint} /> ENTRAR COM BIOMETRIA
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {
              /* <div className="row">
              {
                process.env.REACT_APP_LOGO_URL ?
                <div className="col-md-12 text-center">
                    Registro de contrato de financiamento de veículos
                </div>
                :
                <div className="col-md-12 text-center">
                    <strong>E-REGISTRO</strong> - Registro de contrato de financiamento de veículos<br/>  &copy; 2018 Alias
                </div>
              }

            </div> */
              this.state.samlError ? toastr.warning("Atenção", this.state.samlError) : ""
            }
          </div>
        </div>
        <Modal visible={this.state.show2fa}>
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-body text-center">
                  <h4>Verificação em duas etapas</h4>
                  <p>Um código de verificação foi enviado para seu email</p>
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit2fa.bind(this))}>
                    <div className="row justify-content-center">
                      <div className="col-md-6 text-center">
                        <Field
                          name="uuid2fa"
                          label="Digite o Código"
                          component={TextIconField}
                          icon="fa-key"
                          type="text"
                          required
                          maxlength={6}
                          placeholder="_ _ _ _ _ _"
                          validate={[FieldValidation.nospace]}
                          normalize={FieldNormalize.NUMBER}
                          style={{
                            letterSpacing: "0.2em",
                            textAlign: "center",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="text-center">
                          <button type="button" className="btn btn-secondary mr-2" onClick={this.cancel2fa}>
                            Cancelar
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Verificar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal visible={this.state.showSelectFin} dialogClassName="modal-lg">
          <div className="text-center mt-3 mb-3">
            <h5>Selecione uma financeira para prosseguir</h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-12">
                        <Field name="idFinanceira" label="Financeira" required data={this.state.financeiras} component={SelectField} type="text" valueField="idFinanceira" textField="razaoSocial" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <button type="button" className="btn btn-secondary" onClick={this.cancelSelectFin}>
                            Cancelar
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Footer
          copyright={this.props.configLayout && this.props.configLayout.copyright ? this.props.configLayout.copyright : null}
          footer={this.props.configLayout && this.props.configLayout.footer ? this.props.configLayout.footer : null}
        />
      </>
    );
  }

  cancel2fa = () => {
    this.setState({ show2fa: !this.state.show2fa, captchaNotSelect: true });
    grecaptcha.reset();
  };

  formSubmit2fa(values) {
    const errors = {};
    if (!values.uuid2fa || values.uuid2fa.length === 0 || !values.uuid2fa.trim()) {
      errors.uuid2fa = "Campo obrigatório";
    }
    if (errors && !_.isEmpty(errors)) {
      throw new SubmissionError(errors);
    }

    Promise.resolve(api.Auth.login(values.username.replace(/[^\d]/g, ""), values.password, null, null, values.uuid2fa))
      .then((auth) => {
        this.props.onLogin(auth);
      })
      .catch(function (resp) {
        const message = resp.includes("Usuário ou senha inválidos") ? resp.replace("Usuário ou senha inválidos", "Código inválido") : resp;
        toastr.warning("Atenção", message);
      });
  }

  cancelSelectFin = () => {
    this.setState({ showSelectFin: !this.state.showSelectFin, captchaNotSelect: true });
    grecaptcha.reset();
  };

  formSubmit(values) {
    if (this.state.showSelectFin && !values.idFinanceira) {
      toastr.warning("Atenção", "Selecione uma financeira");
    } else if (this.state.biometria) {
      this.onVerifySignature(this.state.assertionObj, values);
    } else {
      var btn = this.refs.btn;
      btn.setAttribute("disabled", "disabled");
      Promise.resolve(api.Auth.login(values.username.replace(/[^\d]/g, ""), values.password, this.state.valueCaptcha, values.idFinanceira))
        .then((auth) => {
          if (auth.possui2fa) {
            toastr.success("Sucesso", "Código de acesso enviado para o email cadastrado.");
            this.setState({ show2fa: true });
          } else if (auth.financeiras) {
            this.props.dispatch(change("LoginForm", "idFinanceira", auth.financeiras[0].idFinanceira));
            this.setState({ showSelectFin: true, financeiras: auth.financeiras });
          } else {
            this.props.onLogin(auth);
          }
          btn.removeAttribute("disabled");
        })
        .catch(
          function (resp) {
            grecaptcha.reset();
            //btn.removeAttribute("disabled");
            this.setState({ captchaNotSelect: !this.state.captchaNotSelect });
            toastr.warning("Atenção", resp);
          }.bind(this)
        );
    }
  }

  loginMicrosoft(values) {
    var msalConfig = {
      auth: {
        clientId: "ac559fd8-8b66-47de-8018-f15a2302a93b",
        authority: "https://login.microsoftonline.com/common",
        redirectURI: window.location.origin,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    };

    var myMSALObj = new Msal.UserAgentApplication(msalConfig);
    var requestObj = {
      scopes: ["user.read"],
    };
    var p = this.props;
    myMSALObj
      .loginPopup(requestObj)
      .then(function (loginResponse) {
        //Login Success callback code here
        myMSALObj.acquireTokenSilent(requestObj).then(function (tokenResponse) {
          Promise.resolve(api.Auth.loginMicrosoft(tokenResponse.accessToken))
            .then((auth) => {
              p.onLogin(auth);
            })
            .catch(function (resp) {
              toastr.warning("Atenção", resp);
            });
        });
      })
      .catch(function (error) {});
  }

  loginBiometric(values) {
    Promise.resolve(api.Profile.getChallengePublicKey())
      .then((ret) => {
        console.log(ret);
        navigator.credentials
          .get({
            publicKey: {
              challenge: b64stringToUint8Array(base64url(ret.challenge)),
              rpId: document.domain,
              allowCredentials: [
                {
                  type: "public-key",
                  id: b64stringToUint8Array(ret.publicKey),
                },
              ],
              userVerification: "discouraged",
              timeout: 90000,
            },
          })
          .then(async (assertionObj) => {
            this.setState({ assertionObj: assertionObj });
            await this.onVerifySignature(assertionObj);
          });
      })
      .catch(function (resp) {
        toastr.warning("Atenção", "Biometria não cadastrada para este dispositivo");
      });
  }

  async onVerifySignature(assertionObj, values) {
    console.log(assertionObj);
    let webAuthnAttestation = publicKeyCredentialToJSON(assertionObj);
    if (values) {
      webAuthnAttestation.idFinanceira = values.idFinanceira;
    }
    console.log(webAuthnAttestation);
    Promise.resolve(api.Profile.verifySignature(webAuthnAttestation))
      .then((auth) => {
        if (auth.financeiras) {
          this.props.dispatch(change("LoginForm", "idFinanceira", auth.financeiras[0].idFinanceira));
          this.setState({ showSelectFin: true, financeiras: auth.financeiras, biometria: true });
        } else {
          this.props.onLogin(auth);
        }
      })
      .catch(function (resp) {
        toastr.warning("Atenção", resp);
      });
  }

  loginRecoveryKey(value) {
    Promise.resolve(api.Auth.loginRecovery(value))
      .then((auth) => {
        this.props.onLogin(auth);
      })
      .catch(function (resp) {
        toastr.warning("Atenção", resp);
      });
  }

  // formSubmit(values) {
  //   var btn = this.refs.btn;
  //   btn.setAttribute("disabled", "disabled");
  //   Promise.resolve(api.Auth.login(values.username.replace(/[^\d]/g, ""), values.password, this.state.valueCaptcha))
  //     .then((auth) => {
  //       this.props.onLogin(auth);
  //       btn.removeAttribute("disabled");
  //     })
  //     .catch(function (resp) {
  //       btn.removeAttribute("disabled");
  //       toastr.warning("Atenção", resp);
  //     });
  // }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (payload) => dispatch({ type: LOGIN, payload }),
});

const form = reduxForm({
  form: "LoginForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Login));
